import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styles from "./footer.module.scss"

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <footer className={styles.footer}>
      <div className={styles.footerLayout}>
        <div className="row">
          <div className="col-6 col-md">
            <h5>Servicios</h5>
            <ul className="list-unstyled text-small">
              <li>
                Medicina general, cirugía, laboratorio, diagnóstico por imagen,
                tienda especializada, etc.
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Horario</h5>
            <ul className="list-unstyled text-small">
              <li>Lunes a viernes: 11:00 - 14:00 y 17:00 - 20:00 h</li>
              <li>Sábados: 11:00 - 14:00 h</li>
              <li>
                <i>Horario de verano: Cerrado los sábados</i>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Dónde estamos</h5>
            <ul className="list-unstyled text-small">
              <li>Paseo del Sol nº 2</li>
              <li>28880 Meco</li>
              <li>Madrid</li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Información</h5>
            <ul className="list-unstyled text-small">
              <li>Teléfono: 91 886 05 15</li>
              <li>WhatsApp: 91 886 05 15</li>
              <li>Urgencias 24h: 91 529 69 09</li>
              <li>eMail: cvmeco@cvmeco.es</li>
            </ul>
          </div>
        </div>
        <br />
        <p className={styles.rights}>
          © 1995 - {new Date().getFullYear()} {data.site.siteMetadata.author}.
          Todos los derechos reservados.
        </p>
      </div>
    </footer>
  )
}

export default Footer
