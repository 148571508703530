import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"

import "./header.scss"

function Header() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      medicalServices: allMarkdownRemark(
        limit: 100
        filter: { fileAbsolutePath: { regex: "/(medical-services)/.*.md$/" } }
        sort: { fields: frontmatter___title, order: ASC }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              path
            }
          }
        }
      }
      seasons: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 1
        filter: { fileAbsolutePath: { regex: "/(seasons)/.*.md$/" } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `)

  return (
    <header>
      <Navbar className="fixed-top" expand="lg">
        <div className="navbar-content">
          <Navbar.Brand href="/">
            <Img fluid={data.file.childImageSharp.fluid} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavDropdown title="Servicios" id="basic-nav-dropdown">
                {data.medicalServices.edges
                  .filter(item => item.node.frontmatter.name.length > 0)
                  .filter(item => item.node.frontmatter.path.length > 0)
                  .map(({ node: item }) => {
                    return (
                      <Link
                        key={item.id}
                        to={item.frontmatter.path}
                        className="dropdown-item"
                      >
                        {item.frontmatter.name}
                      </Link>
                    )
                  })}
              </NavDropdown>
              <Nav.Item>
                <Link to="/seguros-de-salud" className="nav-link">
                  Seguros de salud
                </Link>
              </Nav.Item>
              <NavDropdown title="Cajón de veterinario" id="basic-nav-dropdown">
                {data.seasons.edges
                  .filter(item => item.node.frontmatter.title.length > 0)
                  .filter(item => item.node.frontmatter.path.length > 0)
                  .map(({ node: item }) => {
                    return (
                      <Link
                        key={item.id}
                        to={item.frontmatter.path}
                        className="dropdown-item"
                      >
                        Campaña actual
                      </Link>
                    )
                  })}
                <Link to="/equipo-humano" className="dropdown-item">
                  Equipo humano
                </Link>
                <Link to="/normativa-meco" className="dropdown-item">
                  Normativa Meco
                </Link>
                <NavDropdown.Divider />
                <Link to="/blog" className="dropdown-item">
                  Blog
                </Link>
              </NavDropdown>
              <Nav.Item>
                <Link to="/donde-estamos" className="nav-link">
                  Dónde estamos
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default Header
